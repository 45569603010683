.EditTodo {
    margin-left:10%;
    margin-right: 10%;
    margin-top: 50px;
}

.radio-group {
    margin-top: 20px;
}

.btn-edit {
    margin-top: 25px;
    margin-right: 10px;
}

.done {
    margin-top: 20px;
}