/* Анимация для плавного появления */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(0px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.messageForm {
  display: flex;
  flex-direction: column;
  background-color: #2b2d31;
  max-width: 520px;
  margin-left: 25px;
  align-items: flex-start;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeIn 0.5s forwards;

  .countForm {
    display: flex;
    justify-content: space-between; /* Лейбл слева, инпуты справа */
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
  }
}

.countText {
  font-size: 16px;
  line-height: 1.5;
  color: #ffffff;
  flex: 1; /* Лейбл занимает левую часть */
}
.messageCount,
.messageName {
  background-color: #2b2d31;
  color: #fff;
  border: 1px solid #666;
  margin-left: 10px;
  margin-bottom: 10px;
  flex: 1;
  max-width: 200px;
  align-items: stretch;
}

.checkbox {
  background-color: #2b2d30;
  color: #fff;
  border: 1px solid #666;
  margin-left: 10px;
  margin-bottom: 10px;
  flex: 1;
  max-width: 30px;
  align-items: stretch;
}

.checkbox {
  width: auto;
}

.messageForm textarea {
  width: 490px; /* Используем 100% ширину родительского контейнера */
  height: 200px; /* Уменьшаем высоту, чтобы форма была более компактной */
  padding: 10px;
  border-radius: 5px;
  border: 1px solid rgb(49, 49, 49);
  resize: none;
  font-size: 16px;
  line-height: 1.5;
  color: #ffffff;
  background-color: #2b2d31; /* Белый фон для текстовой области */
  font-size: 18px; /* Размер текста */
  line-height: 1.6; /* Высота строки для улучшения читаемости */
}

.messageForm textarea::placeholder {
  color: #aaa;
}

.messageForm .buttonContainer {
  display: flex;
  justify-content: flex-end; /* Выравнивание кнопок по правому краю */
  gap: 10px; /* Расстояние между кнопками */
  margin-top: 10px; /* Отступ сверху для кнопок */
  width: 100%; /* Занимает всю ширину родительского контейнера */
}

.messageForm button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  background-color: #113d6d; /* Основной цвет кнопок */
  transition: background-color 0.2s ease;
  width: 100%;
}

.messageForm button:hover {
  background-color: #0056b3; /* Темный оттенок при наведении */
}

.messageForm button + button {
  background-color: #6c757d; /* Цвет для кнопки "Закрыть" */
}

.messageForm button:disabled {
  background-color: #007bff; /* Цвет кнопки в состоянии "disabled" */
  opacity: 0.6; /* Прозрачность кнопки в состоянии "disabled" */
  cursor: not-allowed; /* Курсор при наведении на неактивную кнопку */
}

.messageForm button + button:hover {
  background-color: #5a6268; /* Темный оттенок для "Закрыть" */
}
