.table-todo {
  color: #676767;
}

.desc-heading {
  text-align: start;
}

.action-heading {
  text-align: end;
}

.priority-heading {
  text-align: end;
}

.table-done {
  margin-top: 30px;
}
.not-empty {
  display: none;
}
